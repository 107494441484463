import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import fa from 'element-ui/src/locale/lang/fa'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: { title: 'Dashboard', icon: 'dashboard' }
    }]
  },
  {
    path: '/user',
    component: Layout,
    redirect: '/user/list',
    alwaysShow: true,
    meta: {
      title: '用户', icon: 'user'
    },
    children: [
      {
        path: 'list',
        name: 'UserList',
        component: () => import('@/views/user/list/index'),
        meta: { title: '用户列表' }
      },
      {
        path: 'detail/:userId',
        name: 'UserDetail',
        hidden: true,
        component: () => import('@/views/user/detail/index'),
        props: true,
        meta: { title: '用户详情' }
      }
    ]
  },
  {
    path: '/order',
    component: Layout,
    redirect: '/order/list',
    alwaysShow: true,
    meta: {
      title: '订单', icon: 'el-icon-tickets'
    },
    children: [
      {
        path: 'list',
        name: 'OrderList',
        component: () => import('@/views/order/list/index'),
        meta: { title: '订单列表' }
      },
      {
        path: 'detail/:orderId',
        name: 'OrderDetail',
        hidden: true,
        props: true,
        component: () => import('@/views/order/detail/index'),
        meta: { title: '订单详情' }
      }
    ]
  },
  {
    path: '/pay',
    component: Layout,
    redirect: '/pay/list',
    alwaysShow: true,
    meta: {
      title: '支付', icon: 'el-icon-money'
    },
    children: [
      {
        path: 'list',
        name: 'PayList',
        component: () => import('@/views/pay/list/index'),
        meta: { title: '支付列表' }
      }
      // {
      //   path: 'detail/:flowNo',
      //   name: 'PayDetail',
      //   hidden: true,
      //   props: true,
      //   component: () => import('@/views/pay/detail/index'),
      //   meta: { title: '支付详情' }
      // }
    ]
  },
  {
    path: '/article',
    component: Layout,
    redirect: '/article/activity/list',
    meta: { title: '文章', icon: 'el-icon-document' },
    children: [
      {
        path: 'activity/list',
        name: 'ActivityList',
        component: () => import('@/views/article/activity/list'),
        meta: { title: '福利活动' }
      },
      {
        path: 'activity/edit/:id',
        name: 'ActivityEdit',
        hidden: true,
        component: () => import('@/views/article/activity/edit'),
        meta: { title: '活动编辑' }
      },
      {
        path: 'activity/new',
        name: 'ActivityNew',
        hidden: true,
        component: () => import('@/views/article/activity/edit'),
        meta: { title: '活动新建' }
      },
      {
        path: 'news/list',
        name: 'NewsList',
        component: () => import('@/views/article/news/list'),
        meta: { title: '新闻资讯' }
      },
      {
        path: 'news/edit/:id',
        name: 'NewsEdit',
        hidden: true,
        component: () => import('@/views/article/news/edit'),
        meta: { title: '新闻编辑' }
      },
      {
        path: 'news/new',
        name: 'NewsNew',
        hidden: true,
        component: () => import('@/views/article/news/edit'),
        meta: { title: '新闻新建' }
      },
      {
        path: 'evaluation/list',
        name: 'EvaluationList',
        component: () => import('@/views/article/evaluation/list'),
        meta: { title: '评测实录' }
      },
      {
        path: 'evaluation/edit/:id',
        name: 'EvaluationEdit',
        hidden: true,
        component: () => import('@/views/article/evaluation/edit'),
        meta: { title: '评测编辑' }
      },
      {
        path: 'evaluation/new',
        name: 'EvaluationNew',
        hidden: true,
        component: () => import('@/views/article/evaluation/edit'),
        meta: { title: '评测新建' }
      }
    ]
  },
  // {
  //   path: '/cms',
  //   component: Layout,
  //   redirect: '/cms/user',
  //   alwaysShow: true,

  //   meta: { title: '后台管理', icon: 'el-icon-document' },
  //   children: [
  //     {
  //       path: 'user',
  //       name: 'User',
  //       component: () => import('@/views/cms/user/index'),
  //       meta: { title: '人员管理' }
  //     },
  //     {
  //       path: 'competence',
  //       name: 'Competence',
  //       component: () => import('@/views/competence/index'),
  //       meta: { title: '权限管理' }
  //     }

  //   ]
  // },
  {
    path: '/website',
    component: Layout,
    redirect: '/website/feedback',
    alwaysShow: true,
    meta: { title: '官网', icon: 'el-icon-document' },
    children: [
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/views/website/index'),
        meta: { title: '用户反馈' }

      },
      {
        path: 'detail/:id',
        name: 'detail',
        hidden: true,
        component: () => import('@/views/website/detail/detail'),
        meta: { title: '问题描述详情' }
      }

    ]
  },
  {
    path: '/app',
    component: Layout,
    meta: { title: 'App', icon: 'el-icon-mobile', noRedirect: true },
    alwaysShow: true,
    redirect: '/app/news/list',
    children: [
      {
        path: 'news/list',
        name: 'AppNewsList',
        meta: { title: '文章列表' },
        component: () => import('@/views/app/news/list/index')
      },
      {
        path: 'news/edit/:id',
        name: 'AppNewsEdit',
        meta: { title: '文章编辑' },
        hidden: true,
        component: () => import('@/views/app/news/edit/index'),
        props: true
      },
      {
        path: 'news/new',
        name: 'AppNewsNew',
        meta: { title: '新建文章' },
        hidden: true,
        component: () => import('@/views/app/news/edit/index'),
        props: {
          id: null
        }
      },
      {
        path: 'news/category',
        name: 'AppNewsCategory',
        meta: { title: '分类目录' },
        component: () => import('@/views/app/news/category /CategoryList')
      }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/pay/list',
    children: [
      {
        path: '',
        name: 'Setting',
        component: () => import('@/views/setting/index'),
        meta: { title: '设置', icon: 'el-icon-setting' }
      }
    ]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
