import request from '@/utils/request'
import { addVersionPath } from '@/api/utils'

const BASE_PATH = '/user-center'

export function login(data) {
  return userRequest({
    url: addVersionPath('/admin/login'),
    method: 'post',
    data
  })
}

/**
 * 修改后台用户密码
 * @param {string} oldPassword 旧密码
 * @param {string} newPassword 新密码
 * @return {Promise}
 */
export function changePassword(oldPassword, newPassword) {
  return userRequest({
    url: addVersionPath('/admin/reset/password'),
    method: 'post',
    data: {
      newPassword,
      oldPassword
    }
  })
}

/**
 * 用户列表
 * @param  {Object} params 参数对象
 * @param {string} params.nickName 昵称
 * @param {string} params.phone 电话
 * @param {string} params.mail 邮箱
 * @param {number} params.page 页码
 * @param {number} params.size 页大小
 * @return {Promise}
 */
export function userList(params) {
  return userRequest({
    url: addVersionPath('/user/page'),
    method: 'get',
    params
  })
}

/**
 * 用户详情
 * @param {string} userId 用户ID
 */
export function userInfo(userId) {
  return userRequest({
    url: addVersionPath('/user/info'),
    method: 'get',
    params: {
      userId
    }
  })
}

/**
 * 冻结或解冻用户
 * @param {string} userId 用户id
 * @param {string} status FROZEN冻结/UN_FROZEN解冻
 * @return {Promise}
 */
export function changeUserStatus(userId, status) {
  return userRequest({
    url: addVersionPath('/user/frozen'),
    method: 'post',
    data: {
      status,
      userId
    }
  })
}

/**
 * 分页获取用户交易流水
 * @param params 参数对象
 * @param {string} params.userId 用户id
 * @param {number} params.page 分页页码
 * @param {number} params.size 分页大小
 */
export function userTradeFlow(params) {
  return userRequest({
    url: addVersionPath('/user/account/page'),
    method: 'get',
    params
  })
}

/**
 * 用户请求包装
 * @param  option
 * @return {Promise}
 */
function userRequest(option) {
  option.url = `${BASE_PATH}${option.url}`
  return request(option)
}
