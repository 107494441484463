/**
 *
 * @param path {string} 接口路径 以‘/’开头
 * @param version {number} api 版本
 * @param subPath {string}
 * @returns {string} 添加过版本信息的路径
 */
export function addVersionPath(path, subPath = '', version = 1) {
  if (subPath) {
    return `/manage/${subPath}/v${version}${path}`
  }
  return `/manage/v${version}${path}`
}
