import { login, logout } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { encryptAccountPwd } from '@/utils/crypt'
import { delState, saveState, getState } from '@/utils/persisted-state'
import { PERSISTED_KEY_USER } from '@/utils/constant'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getState(PERSISTED_KEY_USER) || {
      userId: '',
      token: '',
      expirationTime: 0,
      channelCode: '',
      version: '',
      avatar: '',
      name: ''
    }
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER: (state, user) => {
    state.userInfo = user
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: encryptAccountPwd(password) }).then(response => {
        const { value } = response
        value.name = value.name || username
        value.avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
        commit('SET_TOKEN', value.token)
        commit('SET_USER', value)
        setToken(value.token)
        saveState(PERSISTED_KEY_USER, value)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const { data } = response
  //
  //       if (!data) {
  //         return reject('Verification failed, please Login again.')
  //       }
  //
  //       const { name, avatar } = data
  //
  //       commit('SET_NAME', name)
  //       commit('SET_AVATAR', avatar)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      removeToken()
      commit('RESET_STATE')
      delState(PERSISTED_KEY_USER)// must remove  token  first
      resetRouter()
      resolve()
    })
  },

  // remove token
  resetUser({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      delState(PERSISTED_KEY_USER)// must remove  token  first
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

