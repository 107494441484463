import { saveState, getState, delState } from '@/utils/persisted-state'
import { PERSISTED_KEY_TOKEN } from '@/utils/constant'

export function getToken() {
  return getState(PERSISTED_KEY_TOKEN)
}

export function setToken(token) {
  return saveState(PERSISTED_KEY_TOKEN, token)
}

export function removeToken() {
  return delState(PERSISTED_KEY_TOKEN)
}
