import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

/**
 * 格式化输出时长
 * @param duration 时长
 * @param timeUnit 单位 默认 ms
 * @param formatStr 格式
 * @return {string} 格式化字符串
 */
export function formatDuration(duration, timeUnit = 'ms', formatStr = 'HH:mm:ss') {
  return dayjs.duration(duration, timeUnit).format(formatStr)
}

export function formatDateTime(date, formatStr = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(date).format(formatStr)
}

export default {
  formatDuration
}
