
export function saveState(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getState(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function delState(key) {
  return localStorage.removeItem(key)
}

export function delAllState() {
  return localStorage.clear()
}
