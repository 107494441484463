import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { encryptRequestParams } from '@/utils/crypt'
import qs from 'qs'

const NEED_BODY_METHOD = ['PUT', 'POST', 'DELETE', 'PATCH']

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // }
    if (isNeedBody(config.method)) {
      // qs 默认忽略值为 undefined ，设置 skipNulls 为true 时 可以忽略 undefined and null value
      config.headers = { ...generateRequestHeaders(config.data, true), ...config.headers }
      if (!(config.data instanceof FormData)) {
        config.data = qs.stringify(config.data, { allowDots: true, skipNulls: true })
      }
    } else {
      config.headers = { ...generateRequestHeaders(config.params), ...config.headers }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// function stringifyBody(data) {
//   if ()
// }

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== '0') {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 10 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === '00002' || res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.alert('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          type: 'warning'
        }).finally(() => {
          store.dispatch('user/resetUser').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

function generateRequestHeaders(data = {}, ignoreNull = true) {
  const headers = {
    'access-key': process.env.VUE_APP_API_ACCESS_KEY,
    signature: generateRequestSign(data, ignoreNull),
    'Content-Type': 'application/x-www-form-urlencoded',
    request_id: 'w' + Date.now() + Math.floor(Math.random() * 1000)
  }
  const token = getToken()
  if (token) {
    headers['token'] = token
  }
  return headers
}

function generateRequestSign(data, ignoreNull = true) {
  return encryptRequestParams(data, process.env.VUE_APP_API_ACCESS_KEY, process.env.VUE_APP_API_ACCESS_SECRET, ignoreNull)
}

function isNeedBody(method = 'GET') {
  return NEED_BODY_METHOD.includes(method.toUpperCase())
}

export default service
