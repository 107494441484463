import SHA1 from 'crypto-js/sha1'
import HEX from 'crypto-js/enc-hex'
import BASE64 from 'crypto-js/enc-base64'
import HMAC_SHA1 from 'crypto-js/hmac-sha1'
const SALT = '6rXrfIcpi82o'

// const API_ACCESS_KEY = process.env.API_ACCESS_KEY
// const API_ACCESS_SECRET = process.env.API_ACCESS_SECRET

export function encryptAccountPwd(password) {
  const saltPassword = password + SALT
  return btoa(SHA1(saltPassword).toString(HEX))
}

export function encryptRequestParams(params, accessKey, accessSecret, ignoreNull = true) {
  let clonedParams = {}
  if (!(params instanceof FormData)) {
    clonedParams = { ...params }
  }
  clonedParams['access-key'] = accessKey
  const sortKeys = Object.keys(clonedParams).sort()
  const keyValueArray = []
  // 保持和 axios 统一
  // https://github.com/axios/axios/issues/1139
  for (const key of sortKeys) {
    if (clonedParams[key] === null || clonedParams[key] === undefined) {
      if (ignoreNull) {
        console.log('ignore key', key)
        continue
      } else {
        console.log('empty key', key)
        clonedParams[key] = ''
      }
    }
    const value = clonedParams[key]
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const arrayValue = value[i]
        if (typeof arrayValue === 'object') {
          const valueSortedKeys = Object.keys(arrayValue).sort()
          for (const valueSortedKey of valueSortedKeys) {
            const paramsKeyStr = `${key}[${i}].${valueSortedKey}`
            // -_.!~*'()
            const encodeParamsKeyStr = encodeURIComponent(paramsKeyStr)
              .replace(/\+/g, '%20')
              .replace(/\*/g, '%2A')
              .replace(/%7E/g, '~')
              .replace(/\(/g, '%28')
              .replace(/\)/g, '%29')
              .replace(/!/g, '%21')
              .replace(/'/g, '%27')
            keyValueArray.push(`${encodeParamsKeyStr}=${arrayValue[valueSortedKey]}`)
          }
        } else {
          const paramsKeyStr = `${key}[${i}]`
          const encodeParamsKeyStr = encodeURIComponent(paramsKeyStr)
            .replace(/\+/g, '%20')
            .replace(/\*/g, '%2A')
            .replace(/%7E/g, '~')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/!/g, '%21')
            .replace(/'/g, '%27')
          keyValueArray.push(`${encodeParamsKeyStr}=${arrayValue}`)
        }
      }
    } else {
      keyValueArray.push(`${key}=${clonedParams[key]}`)
    }
  }
  const signStr = keyValueArray.join('&')
  // console.debug('signStr:', signStr)
  const encodeSignStr = encodeURIComponent(signStr)
    .replace(/\+/g, '%20')
    .replace(/\*/g, '%2A')
    .replace(/%7E/g, '~')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
  // console.log('URLencode: ' + encodeSignStr)
  // console.log(HMAC_SHA1(encodeSignStr, accessSecret).toString(BASE64))
  return HMAC_SHA1(encodeSignStr, accessSecret).toString(BASE64)
}

export default {
  encryptAccountPwd,
  encryptRequestParams
}

